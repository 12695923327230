import React, { useRef, useState } from "react";
import "./Floatwindow.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import { BiPhoneCall } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
const Floatwindow = () => {
  const [show, setshow] = useState(false);

  const contact = useRef();
  return (
    <>
      <div
        ref={contact}
        className={
          show
            ? " contact-sidebar-wrapper"
            : " contact-sidebar-wrapper  translate-sidebar"
        }
      >
        <div className="call-us-box position-relative d-flex justify-content-end ">
          {show ? (
            <button
              className=" hide-btn position-absolute"
              onClick={() => setshow(false)}
            >
              Hide
              <IoMdArrowDropdown className="fs-4" />
            </button>
          ) : (
            <button
              className=" hide-btn position-absolute"
              onClick={() => setshow(true)}
            >
              Get In Touch
              <IoMdArrowDropup className="fs-4" />
            </button>
          )}

          <div className="d-flex flex-column justify-content-evenly w-100 align-items-center">
            <a href="tel:+917055570270" target="_blank">
              <div className="d-flex flex-column">
                {/* <img
                  src={call}
                  alt=""
                  className="img-fluid mb-1"
                  width={"40px"}
                /> */}
                <BiPhoneCall size={40} color="#2eb086" />
                {/* <span className="fs12 fw600 dark-color">Call Us</span> */}
              </div>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=7055570270&text=Hello%20there!"
              target="_blank"
            >
              {" "}
              <div className="d-flex flex-column align-items-center">
                {/* <img
                  src={email}
                  alt=""
                  width={"40px"}
                  className="img-fluid mb-1"
                /> */}
                <BsWhatsapp size={40} color="#2eb086" />
                {/* <span className="fs12 fw600 dark-color">Write to Us</span> */}
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Floatwindow;
