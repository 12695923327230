// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#05bd8e",
};
root.render(
  <React.StrictMode>
     <Suspense
      fallback={
        <div className="d-flex justify-content-center align-items-center loader">
          <MoonLoader
            color={"#05bd8e"}
            loading={true}
            cssOverride={override}
            size={150}
          />{" "}
        </div>
      }
    >


    <App />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
