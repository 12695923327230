// import logo from "./logo.svg";
import "./App.css";
import React, { Suspense, useState,useRef } from "react";

import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
// import Home from './components/Home/Home';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Floatwindow from "./components/FloatWindow/Floatwindow";
// import Footer from "./components/Footer/Footer";

const Scrolltop = React.lazy(() => import("./components/Scrolltop/Scrolltop"));
const Home = React.lazy(() => import("./components/Home/Home"));
const Footer = React.lazy(() => import("./components/Footer/Footer"));

function App() {
 
  return (
    <>
      <BrowserRouter>
        <Scrolltop>
          <Navbar />
          <Routes>
            {/* <Route path="/auth/*" element={<WithoutNavbar />} /> */}
            <Route path="/" element={<Home />} />
          </Routes>
          <Footer/>
        </Scrolltop>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Floatwindow/>
    </>
  );
}

export default App;
